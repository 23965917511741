import { useEffect, useState } from 'react';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import {
  type GameLikeClickedProps,
  GamePackFilters,
  useRedirectToSearch,
} from '../../components/Game/GameCenter';
import { OnboardingTasksRow } from '../../components/Onboarding';
import {
  EditCategoriesButton,
  useFeaturedTags,
} from '../../components/Tagging';
import { useUser } from '../../components/UserContext';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import {
  type GamePacksResponse,
  type Paginator,
} from '../../services/api-service';
import { OrganizerRoleUtils, RoleUtils } from '../../types';
import { type GamePack } from '../../types/game';
import { getTagLink, isVirtualTag } from '../../types/tag';
import { useGamePackContext } from './Context';
import { getPaginator } from './index';
import { Row } from './Row';

function Rows(
  props: GameLikeClickedProps<GamePack> & {
    context: EnumsPageName;
    withUntagged: boolean | 'pinToTheTop';
    withPlayAgain: boolean;
    personalized: boolean;
  }
): JSX.Element | null {
  const { data: featuredTags } = useFeaturedTags(
    props.context,
    props.withUntagged,
    props.withPlayAgain
  );

  const [paginatorMap, setPaginatorMap] = useState<Map<
    number,
    Paginator<GamePacksResponse, GamePack>
  > | null>(null);

  useEffect(() => {
    const map = new Map<number, Paginator<GamePacksResponse, GamePack>>();
    featuredTags?.forEach((t) => {
      map.set(t.id, getPaginator(t.id, props.personalized));
    });
    setPaginatorMap(map);
    return () => {
      setPaginatorMap(null);
    };
  }, [featuredTags, props.personalized]);

  if (!paginatorMap) return null;

  return (
    <div>
      {featuredTags?.map((t, i) => {
        const paginator = paginatorMap.get(t.id);
        if (!paginator) return null;
        return (
          <Row
            key={t.id}
            tag={t}
            paginator={paginator}
            lazy={i >= 2}
            linkTo={getTagLink(t)}
            headerNarrowed
            hideIfEmpty={isVirtualTag(t)}
            onGamePackClick={props.onItemClick}
          />
        );
      })}
    </div>
  );
}

export function PublicLibraryV1(
  props: GameLikeClickedProps<GamePack>
): JSX.Element | null {
  const me = useUser();
  const isAdmin = RoleUtils.isAdmin(me);
  const ctx = useGamePackContext();
  useRedirectToSearch(!ctx.embed, 'gamePack');
  const withPlayAgain = useFeatureQueryParam('gp-library-play-again-row');

  return (
    <div className='pb-2 relative'>
      {ctx.embed &&
        !ctx.embedCtx.noOnboardingTasks &&
        !!me.organizer &&
        me.organizer.organization &&
        OrganizerRoleUtils.isOwnerOrAdmin(me.organizer.role) && (
          <>
            <OnboardingTasksRow organization={me.organizer.organization} />
          </>
        )}

      {isAdmin && !ctx.embed && (
        <div className='relative w-full'>
          <div className='absolute right-10 top-4 flex gap-5 items-center'>
            <GamePackFilters />
            <EditCategoriesButton
              context={EnumsPageName.PageNamePublicGamePacks}
            />
          </div>
        </div>
      )}

      {!isAdmin && (
        <div className='relative w-full'>
          <div className='absolute right-10 top-4 flex gap-5 items-center'>
            <GamePackFilters />
          </div>
        </div>
      )}

      <Rows
        context={EnumsPageName.PageNamePublicGamePacks}
        onItemClick={props.onItemClick}
        withUntagged={false}
        withPlayAgain={withPlayAgain}
        personalized={!isAdmin}
      />
    </div>
  );
}

export function PublicLibraryV2(
  props: GameLikeClickedProps<GamePack>
): JSX.Element | null {
  const me = useUser();
  const isAdmin = RoleUtils.isAdmin(me);
  const ctx = useGamePackContext();
  useRedirectToSearch(!ctx.embed, 'gamePack');

  return (
    <div className='pb-2 relative'>
      <div className='mt-4 px-10 py-3 text-white relative flex justify-end items-center gap-4'>
        {isAdmin && !ctx.embed && (
          // TODO: We only have one featured list which is shared across v1 and v2. To avoid any accidental changes,
          // this is hidden. We should add it back once we move to v2.
          <div className='flex gap-5 items-center z-10'>
            {/*<EditFeaturedGamePacksButton />*/}
          </div>
        )}
      </div>
      {isAdmin && !ctx.embed && (
        <div className='relative w-full'>
          <div className='absolute right-10 top-4 flex gap-5 items-center'>
            <GamePackFilters />
            <EditCategoriesButton
              context={EnumsPageName.PageNamePublicGamePacksNg}
            />
          </div>
        </div>
      )}
      {!isAdmin && (
        <div className='relative w-full'>
          <div className='absolute right-10 top-4 flex gap-5 items-center'>
            <GamePackFilters />
          </div>
        </div>
      )}
      <Rows
        context={EnumsPageName.PageNamePublicGamePacksNg}
        onItemClick={props.onItemClick}
        withUntagged={false}
        withPlayAgain={false}
        personalized={!isAdmin}
      />
    </div>
  );
}

export function DiscoveryLibrary(
  props: GameLikeClickedProps<GamePack>
): JSX.Element | null {
  const isAdmin = RoleUtils.isAdmin(useUser());
  const ctx = useGamePackContext();
  useRedirectToSearch(!ctx.embed, 'gamePack');

  return (
    <div className='pt-8 pb-2'>
      <header className='px-10 py-4 text-white flex justify-between items-center'>
        <div className='text-3xl font-bold'>Game Pack Library</div>
        <div className='flex items-center gap-4'>
          <GamePackFilters />
          {isAdmin && !ctx.embed && (
            <EditCategoriesButton
              context={EnumsPageName.PageNameDiscoverGamePacks}
            />
          )}
        </div>
      </header>

      <Rows
        context={EnumsPageName.PageNameDiscoverGamePacks}
        onItemClick={props.onItemClick}
        withUntagged={isAdmin && !ctx.embed ? 'pinToTheTop' : false}
        withPlayAgain={false}
        personalized={!isAdmin}
      />
    </div>
  );
}
