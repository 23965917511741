import { useMemo } from 'react';

import { type GameLikeClickedProps } from '../../components/Game/GameCenter';
import { apiService } from '../../services/api-service';
import { type GamePack } from '../../types/game';
import { virtualTags } from '../../types/tag';
import { useGamePackContext } from './Context';
import { Row } from './Row';

export function MyGamePacks(
  props: GameLikeClickedProps<GamePack>
): JSX.Element {
  const ctx = useGamePackContext();
  const paginator = useMemo(() => apiService.gamePack.getMyGamePacks(), []);
  return (
    <div className='pt-8 pb-6 border-b border-secondary'>
      <Row
        creatable={!ctx.embed}
        tag={virtualTags.my}
        paginator={paginator}
        lazy={false}
        linkTo='/my'
        headerText='My Game Packs'
        onGamePackClick={props.onItemClick}
      />
    </div>
  );
}
