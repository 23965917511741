import { useTitle } from 'react-use';

import { type BreadcrumbNode } from '../../components/Breadcrumbs';
import {
  GameCenterContextProvider,
  type GameLikeMode,
  type GameLikePageType,
  HomeBreadcrumb,
} from '../../components/Game/GameCenter';
import { NotificationCenter } from '../../components/Notification';
import { ProvidersList } from '../../components/ProvidersList';
import { useInstance } from '../../hooks/useInstance';
import {
  apiService,
  type GamePacksResponse,
  type Paginator,
} from '../../services/api-service';
import { type GamePack } from '../../types/game';
import { virtualTags } from '../../types/tag';
import { makeTitle } from '../../utils/common';
import { GamePackContextProvider } from './Context';
import { GamePackCRUDUtility } from './CRUDUtility';
import { DiscoveryLibrary } from './Library';
import { MyGamePacks } from './MyGamePacks';
export * from './List';
export { ImportGamePack } from './Import';
export { PublicLibraryV1 } from './Library';
export * from './Nav';
export * from './GamePackTagDetails';
export * from './EmbeddedPublicLibrary';

export function getPaginator(
  tagId: number,
  personalized: boolean
): Paginator<GamePacksResponse, GamePack> {
  switch (tagId) {
    case virtualTags.untagged.id:
      return apiService.gamePack.getUntaggedPrimeGamePacks();
    case virtualTags.playAgain.id:
      return apiService.gamePack.getPlayedGamePacks();
    default:
      return apiService.gamePack.getGamePacksByTagId(tagId, personalized);
  }
}

export function GamePackCenter(): JSX.Element {
  useTitle(makeTitle('Game Packs'));
  return (
    <div className='w-full h-full'>
      <MyGamePacks />
      <DiscoveryLibrary />
    </div>
  );
}

export const Wrapper = (props: {
  routePrefix: string;
  editRoutePrefix: string;
  breadcrumbLabel: string;
  pageType: GameLikePageType;
  mode: GameLikeMode;
  children?: React.ReactNode;
}): JSX.Element => {
  const breadcrumb = useInstance<BreadcrumbNode>(() => ({
    key: 'GamePacks',
    node: (
      <HomeBreadcrumb label={props.breadcrumbLabel} to={props.routePrefix} />
    ),
  }));

  const providers = [
    <GameCenterContextProvider />,
    <GamePackContextProvider
      breadcrumb={breadcrumb}
      routePrefix={props.routePrefix}
      editRoutePrefix={props.editRoutePrefix}
      embed={false}
      mode={props.mode}
      pageType={props.pageType}
    />,
  ];

  return (
    <ProvidersList providers={providers}>
      {props.children}
      <GamePackCRUDUtility />

      <NotificationCenter
        className='!w-108'
        position='bottom-right'
        toastClassName='rounded my-2'
      />
    </ProvidersList>
  );
};
